import axios from 'axios/index';
import api from '@/api';
import store from '@/store'

//表单验证
let validateForm = async function (formName: string, el: any) {
    let result = await (el[formName] as any)
        .validate()
        .catch((error: any) => error);
    return result;
}

//表单验证
let validateFormItem = async function (formName: string, formItem: object | string, el: any) {
    // let result = await (el[formName] as any)
    //     .validateField(formItem, (err: any) => err);
    let result;
    (el[formName] as any)
        .validateField(formItem, (err: any) => {
            result = err
        });

    if (result) {
        return { state: false, msg: result }
    } else
        return { state: true, msg: "验证通过" }
}

// 清空表单
let resetForm = (formName: string, el: any) => {
    (el[formName] as any).resetFields();
}

// 登录
let login = async (data: any) => {
    const getopt = api.apiList.login;
    const opt = getopt();
    //@ts-ignore
    let result = await axios({
        url: opt.url,
        method: opt.method,
        headers: opt.headers,
        data: data
    }).then((res: any) => {
        if (res.data.api_token) {
            saveToken(res.data.api_token);
            saveUserId(res.data.id);
            saveUser(res.data);
            return { status: 0, msg: "登录成功", data: res.data }
        } else {
            return { status: 1, msg: "登录失败：服务器返回值没有token", data: res.data }
        }
    }).catch((error: any) => {
        if (error.response) {
            if (error.response.status == 422) {
                let errmsg = error.response.data;
                let errmsg_en = error.response.statusText
                // let errmsg = unicodeToWord()
                return { status: 2, msg: `登录失败：[${error.response.status}] ${errmsg}`, msg_en: `register error:[${error.response.status}] ${errmsg_en}`, data: error.response }
            } else {
                return { status: 3, msg: `登录失败：[${error.response.status}]`, data: error.response }
            }
        } else {
            return { status: 4, msg: `登录失败：服务器无response，${error.message}`, data: error.config }
        }
    })
    return result
}

const loginExpert = async (data:any)=>{
    const getopt = api.apiList.loginExpert;
    const opt = getopt();
    //@ts-ignore
    let result = await axios({
        url: opt.url,
        method: opt.method,
        headers: opt.headers,
        data: data
    }).then((res: any) => {
        if (res.data.api_token) {
            saveToken(res.data.api_token);
            saveUserId(res.data.id);
            saveUser(res.data);
            return { status: 0, msg: "登录成功", data: res.data }
        } else {
            return { status: 1, msg: "登录失败：服务器返回值没有token", data: res.data }
        }
    }).catch((error: any) => {
        if (error.response) {
            if (error.response.status == 422) {
                let errmsg = error.response.data;
                let errmsg_en = error.response.statusText
                // let errmsg = unicodeToWord()
                return { status: 2, msg: `登录失败：[${error.response.status}] ${errmsg}`, msg_en: `register error:[${error.response.status}] ${errmsg_en}`, data: error.response }
            } else {
                return { status: 3, msg: `登录失败：[${error.response.status}]`, data: error.response }
            }
        } else {
            return { status: 4, msg: `登录失败：服务器无response，${error.message}`, data: error.config }
        }
    })
    return result
}

/* 注册 */
let regist = async (data: any) => {
    const getopt = api.apiList.regist;
    const opt = getopt();
    let result = await (axios as any)({
        url: opt.url,
        method: opt.method,
        headers: opt.headers,
        data: data
    })
        .then((res: any) => {
            if (res.data.api_token) {
                return { status: 0, msg: "注册成功", data: res.data }
            } else {
                return { status: 1, msg: "注册失败：服务器返回值没有token", data: res.data }
            }
        })
        .catch((error: any) => {
            if (error.response) {
                if (error.response.status == 422) {
                    let err = error.response.data.errors; //返回值
                    let errKey: object = Object.keys(err); //存放"错误信息key"的数组
                    let errmsg = err[Object.keys(err)[0]][0]; //第一个错误msg
                    return { status: 2, msg: `注册失败：[${error.response.status}] ${errmsg}`, data: err }
                } else {
                    return { status: 3, msg: `注册失败：[${error.response.status}]`, data: error.response }
                }
            } else {
                return { status: 4, msg: `注册失败：服务器无response，${error.message}`, data: error.config }
            }
        });

    return result;
}

//提交项目基本信息
let subProjectBasic = async (data: any, pid?: any) => {
    let getopt, opt;
    /* 根据是否有pid 判断是"新增"还是"更新" */
    if (pid) {
        getopt = api.apiList.updateProjectBasic;
        opt = getopt(pid);
    } else {
        getopt = api.apiList.uploadProjectBasic;
        opt = getopt();
    }

    let result = await (axios as any)({
        url: opt.url,
        headers: opt.headers,
        method: opt.method,
        data: data
    }).then((res: any) => {
        if (res.status && res.status === 200) {
            return { status: 0, msg: "提交成功", data: res.data }
        } else {
            return { status: 1, msg: `提交失败:[${res.status ? res.status : '000'}]`, data: res.data }
        }
    }).catch((error: any) => {
        if (error.response) {
            if (error.response.status == 422) {
                let err = error.response.data.errors; //返回值
                let errKey: any = Object.keys(err); //存放"错误信息key"的数组
                let errmsg = err[errKey[0]][0]; //第一个错误msg
                return { status: 2, msg: `提交失败：[${error.response.status}] ${errmsg}`, data: err }
            } else {
                return { status: 3, msg: `提交失败：[${error.response.status}]`, data: error.response }
            }
        } else {
            return { status: 4, msg: `提交失败：服务器无response，${error.message}`, data: error.config }
        }

    })
    return result
}

//项目技术提交
let subProjectTech = async (data: any, id: number | string) => {
    const getopt = api.apiList.uploadProjectTech;
    const opt = getopt(id);
    let result = await (axios as any)({
        url: `${opt.url}`,
        headers: opt.headers,
        method: opt.method,
        data: data
    }).then((res: any) => {
        if (res.status && res.status === 200) {
            return { status: 0, msg: "提交成功", data: res.data }
        } else {
            return { status: 1, msg: `提交失败:[${res.status ? res.status : '000'}]`, data: res.data }
        }
    }).catch((error: any) => {
        if (error.response) {
            if (error.response.status == 422) {
                let err = error.response.data.errors; //返回值
                let errKey: any = Object.keys(err); //存放"错误信息key"的数组
                let errmsg = err[errKey[0]][0]; //第一个错误msg
                return { status: 2, msg: `提交失败：[${error.response.status}] ${errmsg}`, data: err }
            } else {
                return { status: 3, msg: `提交失败：[${error.response.status}]`, data: error.response }
            }
        } else {
            return { status: 4, msg: `提交失败：服务器无response，${error.message}`, data: error.config }
        }

    })
    return result
}


//项目团队提交
let subProjectTeam = async (data: any, id: number | string, isFirst: boolean) => {
    let getopt, opt;
    if (isFirst === true) {
        getopt = api.apiList.uploadProjectTeam;
        opt = getopt(id);
    } else {
        getopt = api.apiList.updateProjectTeam;
        opt = getopt(id);
    }
    let result = await (axios as any)({
        url: `${opt.url}`,
        headers: opt.headers,
        method: opt.method,
        data: data
    }).then((res: any) => {
        if (res.status && res.status === 200) {
            return { status: 0, msg: "提交成功", data: res.data }
        } else {
            return { status: 1, msg: `提交失败:[${res.status ? res.status : '000'}]`, data: res.data }
        }
    }).catch((error: any) => {
        if (error.response) {
            if (error.response.status == 422) {
                let err = error.response.data.errors; //返回值
                let errKey: any = Object.keys(err); //存放"错误信息key"的数组
                let errmsg = err[errKey[0]][0]; //第一个错误msg
                return { status: 2, msg: `提交失败：[${error.response.status}] ${errmsg}`, data: err }
            } else {
                return { status: 3, msg: `提交失败：[${error.response.status}]`, data: error.response }
            }
        } else {
            return { status: 4, msg: `提交失败：服务器无response，${error.message}`, data: error.config }
        }

    })
    console.log(result);
    return result
}

//项目附件提交
let subProjectFile = async (data: any, id: number | string, callback?: any) => {
    const getopt = api.apiList.uploadProjectFile;
    const opt = getopt();
    let result = await (axios as any)({
        url: `${opt.url}/${id}`,
        headers: opt.headers,
        method: opt.method,
        data: data,
        onUploadProgress: (progressEvent: any) => {
            // 使用本地 progress 事件
            if (progressEvent.lengthComputable) {
                let num = Math.round(
                    (progressEvent.loaded / progressEvent.total) * 10 + 90
                );
                callback ? callback(num) : false
            }
        }
    }).then((res: any) => {
        if (res.status && res.status === 200) {
            return { status: 0, msg: "提交成功", data: res.data }
        } else {
            return { status: 1, msg: `提交失败:[${res.status ? res.status : '000'}]`, data: res.data }
        }
    }).catch((error: any) => {
        if (error.response) {
            if (error.response.status == 422) {
                let err = error.response.data.errors; //返回值
                let errKey: any = Object.keys(err); //存放"错误信息key"的数组
                let errmsg = err[errKey[0]][0]; //第一个错误msg
                return { status: 2, msg: `提交失败：[${error.response.status}] ${errmsg}`, data: err }
            } else {
                return { status: 3, msg: `提交失败：[${error.response.status}]`, data: error.response }
            }
        } else {
            return { status: 4, msg: `提交失败：服务器无response，${error.message}`, data: error.config }
        }

    })
    // console.log(result);
    return result
}

//删除项目附件
const delProjectFile = async (id: number) => {
    const getopt = api.apiList.delProjectFile;
    const opt = getopt(id);

    let result = await (axios as any)({
        url: `${opt.url}`,
        headers: opt.headers,
        method: opt.method,
    }).then((res: any) => {
        if (res.status && res.status === 200) {
            return { status: 0, msg: `成功:${res.data}`, data: res.data }
        } else {
            return { status: 1, msg: `失败:[${res.status ? res.status : '000'}]`, data: res.data }
        }
    }).catch((error: any) => {
        if (error.response) {
            if (error.response.status == 422) {
                let err = error.response.data.errors; //返回值
                let errKey: any = Object.keys(err); //存放"错误信息key"的数组
                let errmsg = err[errKey[0]][0]; //第一个错误msg
                return { status: 2, msg: `失败：[${error.response.status}] ${errmsg}`, data: err }
            } else {
                return { status: 3, msg: `失败：[${error.response.status}]`, data: error.response }
            }
        } else {
            return { status: 4, msg: `失败：服务器无response，${error.message}`, data: error.config }
        }

    })
    return result

}

//提交专家信息
const subExpertBasic = async (data: any, eid?: number | string) => {
    let getopt, opt;
    /* 根据是否有pid 判断是"新增"还是"更新" */
    if (eid) {
        getopt = api.apiList.updateExpertBasic;
        opt = getopt(eid);
    } else {
        getopt = api.apiList.uploadExpertBasic;
        opt = getopt();
    }

    let result = await (axios as any)({
        url: opt.url,
        headers: opt.headers,
        method: opt.method,
        data: data
    }).then((res: any) => {
        if (res.status && (res.status === 200 || res.status === 201)) {
            return { status: 0, msg: "提交成功", data: res.data }
        } else {
            return { status: 1, msg: `提交失败:[${res.status ? res.status : '000'}]`, data: res.data }
        }
    }).catch((error: any) => {
        if (error.response) {
            if (error.response.status == 422) {
                let err = error.response.data.errors; //返回值
                let errKey: any = Object.keys(err); //存放"错误信息key"的数组
                let errmsg = err[errKey[0]][0]; //第一个错误msg
                return { status: 2, msg: `提交失败：[${error.response.status}] ${errmsg}`, data: err }
            } else {
                return { status: 3, msg: `提交失败：[${error.response.status}]`, data: error.response }
            }
        } else {
            return { status: 4, msg: `提交失败：服务器无response，${error.message}`, data: error.config }
        }

    })
    return result

}


const getChannel = async (id: number | string) => {
    const getopt = api.apiList.getChannel;
    const opt = getopt(id);
    let result = await axios({
        url: opt.url,
        method: opt.method,
        headers: opt.headers
    }).then((res: any) => {
        if (res.status && res.status === 200) {
            return { status: 0, msg: "注册码正确", data: res.data }
        } else {
            return { status: 1, msg: `验证注册码失败:[${res.status ? res.status : '000'}]`, data: res.data }
        }
    }).catch((error: any) => {
        if (error.response) {
            if (error.response.status == 422) {
                let err = error.response; //返回值
                let errmsg = error.response.data;
                return { status: 2, msg: `验证注册码失败：[${error.response.status}] ${errmsg}`, data: err }
            } else {
                return { status: 3, msg: `验证注册码失败：[${error.response.status}]`, data: error.response }
            }
        } else {
            return { status: 4, msg: `验证注册码失败：服务器无response，${error.message}`, data: error.config }
        }
    })

    return result
}



let saveToken = (data: any) => {
    store.dispatch("getSetToken", data)
    sessionStorage.setItem(
        "api_token",
        data
    )
}

let saveUserId = (data: any) => {
    store.dispatch("getSetUserId", data);
    sessionStorage.setItem("id", data)
}

const saveUser = (data: any) => {
    store.dispatch("getSetUser", data);
}


let unicodeToWord = (v: any) => {
    if (!v) return '';
    v = v.split('\\u');
    let str = '';
    for (let i = 0, len = v.length; i < len; i++) {
        if (v[i]) {
            //@ts-ignore
            str += String.fromCharCode(parseInt(v[i], 16).toString(10));
        }
    }
    return str;
}



export {
    subProjectBasic,
    subProjectTech,
    subProjectTeam,
    subProjectFile,
    getChannel,
    delProjectFile,
    subExpertBasic,
    saveUser,
    loginExpert
}

export default {
    validateForm,
    login,
    regist,
    resetForm,
    validateFormItem,
    // saveToken
}
